<template>
    <div class="task">
        <Loading v-if="loading"></Loading>
        <AddClasses :visible="openClasses" @close="openClasses = false"  :data="currentItem" :edit="editClass" @update="getList"></AddClasses>
        <!-- 添加二级分类 -->
        <a-modal
                title="新增卷子"
                :visible="parentClass.visible"
                @ok="handleOk"
                :confirmLoading="parentClass.confirmLoading"
                @cancel="parentClass.visible = false"
                v-if="parentClass.visible"
                centered
        >
            <a-form :form="form">
                <a-form-item label="新增卷子" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                    <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入新增卷子名称!' }] }]" />
                </a-form-item>
            </a-form>
        </a-modal>
        <div class="clearfix">
            <div class="fL">
                <a-breadcrumb>
                    <a-breadcrumb-item><a href="/practice/exam">考试管理</a></a-breadcrumb-item>
                    <a-breadcrumb-item>{{exam}}</a-breadcrumb-item>
                    <a-breadcrumb-item>{{chapter}}</a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <div class="fR">
                <a-button type="dashed" @click="AddClass(null, 1)">新增卷子</a-button>
            </div>
        </div>

        <a-table :columns="columns" rowKey="id" :dataSource="list" style="margin-top:20px" @expand="expand" :expandedRowKeys="expandedRowKeys" :childrenColumnName="'son'" class="table">
            <template slot="option" slot-scope="text,record">
                <a-button type="primary" style="margin-right:10px" @click="$router.push({path:'/practice/editExamination', query: {type:$route.query.type,exam: record.exam_id,id: record.id}})">编辑小节内容</a-button>
                <a-button type="primary" style="margin-right:10px" @click="$router.push({path:'/practice/editExamPaper', query: {type:$route.query.type,exam: record.exam_id,id: record.id}})">编辑习题</a-button>
                <a-popconfirm
                        title="确定删除吗?"
                        @confirm="deletClass(record.id, record.parent_id)"
                        okText="确定"
                        cancelText="取消"
                >
                    <a-button type="danger">删除</a-button>
                </a-popconfirm>
            </template>
            <template slot="index" slot-scope="text, record">
                <span>{{record.cname}}</span>
            </template>
        </a-table>
        <a-modal title="修改" centered v-model="visible" @ok="editCate">
            <template>
                <a-input v-model="editType" style="margin: 30px  0"/>
            </template>
        </a-modal>
    </div>
</template>
<script>
    const columns = [
        { title: '卷子名称', dataIndex: 'index', scopedSlots: { customRender: 'index' }, },
        { title: '操作', dataIndex: 'option', scopedSlots: { customRender: 'option' },  align: 'center' },
    ]
    import Loading from '@/components/loading'
    import { getPaper, addPaper, delPaper, editPaper, getExamDetail } from '@/libs/practice'
    import AddClasses from '@/components/practice/addClasses'
    export default {
        name:'examination',
        components:{ Loading,AddClasses },
        data(){
            return{
                form: this.$form.createForm(this),
                columns,
                currentId:'',
                classType:1,
                parentClass:{
                    visible:false,
                    confirmLoading:false,
                    visible1:false,
                    confirmLoading1:false,
                },
                id:'',
                exam :'',
                chapter :'',
                loading:false,
                total:'',
                list:[],
                flatList:[],
                pagination:{
                    page:1,
                    size:10
                },
                expandedRowKeys: [],
                visible: false,
                editType: '',
                editId: '',
                currentparent: null,
                //科目详情
                openClasses: false,
                currentItem:'',
                editClass:false
            }
        },
        filters:{
            num(val){
                if (val<9){
                    return `0${val+1}`
                }else{
                    return val
                }
            }
        },
        mounted(){
            if (this.$route.query.exam) {
                let examText = {1:'历年真题',2:'模拟考试',3:'补充题库'};
                getExamDetail({id: this.$route.query.exam}).then(res => {
                    this.exam = res.cname;
                    this.chapter = examText[this.$route.query.type];
                })
            }
            this.getList()
        },
        methods:{
            expand(expanded, record){
                if(!expanded){
                    let index = this.expandedRowKeys.findIndex(el=>el == record.id)
                    this.expandedRowKeys.splice(index, 1)
                }else{
                    this.expandedRowKeys.push(record.id)
                }
            },
            handleOk(e){
                e.preventDefault();
                this.form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        this.parentClass.confirmLoading = true
                        let request = {}
                        request = { exam:this.$route.query.exam,type:this.$route.query.type, ...values }
                        // this.$route.query.exam
                        addPaper(request).then(res=>{
                            this.findItemById(this.list, request, res.id)
                            this.flatList.push(res.id)
                            this.parentClass.visible = false
                            this.parentClass.confirmLoading = false
                            this.getList();
                        },error=>{
                            console.log(error)
                            this.parentClass.confirmLoading = false
                        })
                    }
                });
            },
            findItemById(datas, {id}, data){
                datas.forEach(el => {
                    if(el.id == id){
                        if(el.son && el.son.length){
                            el.son.push({index: `${el.index}-${el.son.length+1}`, level: el.level+1, ...data})
                            let index = this.expandedRowKeys.findIndex(key => key == el.id)
                            this.expandedRowKeys.splice(index, 1)
                            this.expandedRowKeys.push(el.id)
                        }else{
                            el.son = [{
                                index: `${el.index}-1`,
                                level: el.level+1,
                                ...data
                            }]
                            this.expandedRowKeys.push(el.id)
                        }
                    }
                    if(el.son && el.son.length){
                        this.findItemById(el.son, {id}, data)
                    }
                })
            },
            dealArray(data){
                data.forEach((el, index) => {
                    this.flatList.push(el)
                    if(el.level == 0){
                        el.index = index+1
                    }else{
                        el.index += `-${Number(index+1)}`
                    }
                    if(el.son && el.son.length){
                        this.expandedRowKeys.push(el.id)
                        el.son.forEach((son, sonIndex)=>{
                            if(son)
                                son.index =`${el.index}`
                        })
                        this.dealArray(el.son)
                    }else{
                        delete el.son
                    }
                })
            },
            editCate(){
                let request = {
                    id: this.editId,
                    name: this.editType
                }

                editPaper(request).then(res=>{
                    this.visible = false;
                    this.findName(this.list, request)

                }).catch(error=>{
                    console.log(error)
                })
            },
            findName(data, {id, name}){
                data.forEach(el => {
                    if(el.id == id){
                        el.cname = name
                        let index = this.expandedRowKeys.findIndex(key => key == el.parent_id)
                        this.expandedRowKeys.splice(index,1)
                        this.expandedRowKeys.push(el.parent_id)
                    }
                    if(el.son && el.son.length){
                        this.findName(el.son,{id, name})
                    }
                })
            },
            getList(){
                this.loading = true
                this.list = []
                this.expandedRowKeys = []
                this.flatList = []

                getPaper({type:this.$route.query.type,exam_id:this.$route.query.exam}).then(res=>{
                    this.loading = false
                    if(res.length){
                        res.forEach(el=>{
                            el.show = false
                        })
                        this.list = res
                        this.dealArray(this.list)
                    }
                },error=>{
                    this.loading = false
                    console.log(error)
                })
            },
            changeClass({id}){
                this.id = id
                this.getList()
            },
            toggle(item,index){
                this.list[index].show = !item.show
            },
            deletClass(id, parent_id){

                delPaper({id}).then(res=>{
                    this.findDelById(this.list,id, parent_id)
                    //    this.getList()
                },error=>{
                    console.log(error)
                })
            },
            findDelById(data, id, parent_id){
                data.forEach(el => {
                    if(el.id == parent_id){
                        if(el.son.length ==1){
                            delete el.son
                            let index = this.expandedRowKeys.find(key => key == parent_id)
                            this.expandedRowKeys.splice(index, 1)
                        }else{
                            let index = el.son.findIndex(key => key.id == id)
                            el.son.splice(index, 1)
                            el.son.forEach((s, i) => {
                                s.index = `${el.index}-${i+1}`
                            })
                        }
                    }
                    if(el.son && el.son.length){
                        this.findDelById(el.son, id, parent_id)
                    }
                })
            },
            AddClass(item, type){
                if(type != 1){
                    this.currentId = item.id
                    this.currentparent = this.flatList.filter(el => el.id == item.id)[0].cname
                }
                this.classType = type
                this.parentClass.visible = true
            },
            AddClass1(item, type){
                this.currentId = item.id
                this.openClasses = true;
                this.currentItem = null;
                this.editClass = false
                /*this.classType = type
                this.parentClass.visible1 = true*/
            },
        }
    }
</script>
<style lang="less" scoped>
    .iconGroup{
        >*{
            padding: 0 12px;
            display: inline-block;
            vertical-align: middle;
        }
        .headImg{
            width: 36px;
            height: 36px;
            border-radius: 100%;
            background: #f07e66;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                display: block
            }
            &.noImg{
                background: #fff
            }
        }
        .processNum{
            color: #4165e0;
            width: 75px;
            font-weight: bold
        }
    }
    .iconGroup  .anticon{
        font-size:24px;
        color: #4165e0;
        cursor: pointer;
    }
    .btn{
        width: 105px;
    }
    .examList{
        margin-bottom:0;
        &.active{
            background: #5180e0;
            color: #fff;
            .num{
                color: #fff
            }
        }
    }
    .fL .btn{
        background: none;
        color: #5180e0;
        border: 1px solid #5180e0;
        &.active{
            color: #fff;
            background: #5180e0
        }
    }
    .sonList{
        margin: 0 20px;
        background: #fff;
        border: 1px dashed #5180e0;
        border-top: 0;
        padding: 0 20px;
        overflow: hidden;

    }
    .slide-fade-enter-active,.slide-fade-leave-active{
        transition: all .5s ease;
    }
    .slide-fade-enter, .slide-fade-leave-active {
        height: 30%;
        opacity: 0;
    }
    .transformIcon{
        transition: transform .3s ease-in-out;
        &.active{
            color: #fff;
            transform: rotateZ(90deg)
        }
    }
    .iconGroup{
        font-size: 16px
    }
    .sonItem{
        font-size: 16px;
        height: 60px;
        line-height: 60px;
        padding:0 20px;
        border-bottom: 1px dashed #5180e0;
        &:last-child{
            border-bottom: 0
        }
    }
    .table{
        /deep/ .ant-table{
            font-size: 16px;
        }
        .num{
            margin-right: 30px;
        }
    }
</style>
